.App {
  border-radius: 20px;
  max-width: 1100px;
  margin: 40px auto;
  background-size: contain;
  background-position: center;
  padding: 40px;
  background-color: #ff787847;
  backdrop-filter: blur(6px);
  border: 3px solid #222723;
}
button {
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
button:hover {
  background: #39523d;
  color: #fff;
}
.card-grid {
  background-position: center;
  margin-top: 0;
  display: grid;
  grid-gap: 20px;
  padding: 30px;
  border-radius: 20px;
  background-image: url(https://res.cloudinary.com/dzlbb8rlo/image/upload/v1685642218/Language/shutterstock_280524131_qre0jt.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: -4px 8px 11px 5px #000000c4;
}
.tools {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.tool-buttons {
  display: flex;
  gap: 10px;
}
.tool-buttons button {
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.2s ease;
}
.tool-buttons button i {
  margin-right: 10px;
}
.tool-buttons .button-text {
  display: inline-block;
}
.tool-buttons button:hover {
}
.scoreboard {
  text-align: right;
  font-weight: 600;
}