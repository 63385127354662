@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap);
  
/* base styles */
body {
  font-family: Raleway, sans-serif;
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  color: #fff;
  background-color: #161d17;
  background-image: url("https://res.cloudinary.com/dzlbb8rlo/image/upload/v1685644560/Language/bg-repeat_r9zsge.jpg");
  background-repeat: repeat;
  background-position: center;
  background-blend-mode: overlay;}

.App {
  border-radius: 20px;
  max-width: 1100px;
  margin: 40px auto;
  background-size: contain;
  background-position: center;
  padding: 40px;
  background-color: #ff787847;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  border: 3px solid #222723;
}
button {
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
button:hover {
  background: #39523d;
  color: #fff;
}
.card-grid {
  background-position: center;
  margin-top: 0;
  display: grid;
  grid-gap: 20px;
  padding: 30px;
  border-radius: 20px;
  background-image: url(https://res.cloudinary.com/dzlbb8rlo/image/upload/v1685642218/Language/shutterstock_280524131_qre0jt.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: -4px 8px 11px 5px #000000c4;
}
.tools {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.tool-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.tool-buttons button {
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.2s ease;
}
.tool-buttons button i {
  margin-right: 10px;
}
.tool-buttons .button-text {
  display: inline-block;
}
.tool-buttons button:hover {
}
.scoreboard {
  text-align: right;
  font-weight: 600;
}
.card {
  position: relative;
  max-width: 200px;
  overflow: hidden;
  box-shadow: -3px 3px 4px 1px #000;
  border-radius: 15px;
}

.card img {
  width: 100%;
  display: block;
}

.card .front {
  -webkit-transform: rotateY(90deg);
          transform: rotateY(90deg);
  transition: all ease-in 0.2s;
  position: absolute;
  box-shadow: 2px 2px 5px #000;
}

.flipped .front {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  transition-delay: 0.2s;
}

.card .back {
  transition: all ease-in 0.2s;
  transition-delay: 0.2s;
  box-shadow: 2px 2px 5px #000;
}

.flipped .back {
  -webkit-transform: rotateY(90deg);
          transform: rotateY(90deg);
  transition-delay: 0s;
}

@-webkit-keyframes glow {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 5px #32CD32, 0 0 10px #32CD32, 0 0 15px #32CD32, 0 0 20px #32CD32;
  }
  100% {
    box-shadow: none;
  }
}

@keyframes glow {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 5px #32CD32, 0 0 10px #32CD32, 0 0 15px #32CD32, 0 0 20px #32CD32;
  }
  100% {
    box-shadow: none;
  }
}

.card.matched {
  -webkit-animation: glow 2s ease-out forwards;
          animation: glow 2s ease-out forwards;
  border-radius: 15px;
}

.level-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 50px;
  background-image: url(https://res.cloudinary.com/dzlbb8rlo/image/upload/v1685643793/Language/shutterstock_1391152724_hdxdyn.jpg);
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: -6px 8px 20px 5px #000000c4;
}

.level-select .level-card-wrapper {
  position: relative;
  width: 21%;
  padding-bottom: 30%;
  margin: 1rem;
}

.level-select .level-card {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  width: 100%;
  max-height: 350px;
  cursor: pointer;
}

.level-select .level-card.flipped .front {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.level-select .level-card.flipped .back {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  position: absolute;
  top:0;
}

.level-select .level-card.enlarged {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  transition: all 0.5s ease;
  z-index:1000;
}

.level-select .level-card > img, .level-select .level-card > div {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.level-select .level-card .front {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}

.level-select .level-card .back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  position: absolute;
  top: 0;
}

.level-select .level-card .back button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 15px;
  border: 2px solid #0c4128;
  background-color: #0c4128;
  transition: ease-in all 0.2s;
  cursor: pointer;
}

.level-select .level-card .back button:hover {
  border: 2px solid #fff;
  background-color: #fff;
  color: #000;
  transition: ease-in all 0.2s;
}

.level-select .level-card .back .close-btn {
  position: absolute;
  top: 4%;
  color: #000;
  right: 8%;
  cursor: pointer;
}

.level-select img {
  width: 100%;
  border-radius: 15px;
  box-shadow: -5px 5px 6px #000;
}

