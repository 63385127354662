  
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');

/* base styles */
body {
  font-family: Raleway, sans-serif;
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  color: #fff;
  background-color: #161d17;
  background-image: url("https://res.cloudinary.com/dzlbb8rlo/image/upload/v1685644560/Language/bg-repeat_r9zsge.jpg");
  background-repeat: repeat;
  background-position: center;
  background-blend-mode: overlay;}
